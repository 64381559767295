import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

const siteName = " | MKCL";

function loadLayout(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/layouts/${view}.vue`);
}
function loadView(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
function loadCourse(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/courses/${view}.vue`);
}
function loadSolution(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/solutions/${view}.vue`);
}
function loadCareer(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/careers/${view}.vue`);
}
function loadGallery(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/gallery/${view}.vue`);
}
function loadTracks(view) {
    return () => import(/* webpackChunkName: "view-[request]" */ `@/views/tracks/${view}.vue`);
}

export default createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            name: "Default",
            component: loadLayout("Default"),
            children: [
                {
                    path: "/",
                    name: "home",
                    component: loadView("Home"),
                    meta: {
                        title: "Home" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "main"
                    }
                },
                // Courses
                {
                    path: "/courses",
                    name: "courses",
                    component: loadView("Courses"),
                    meta: {
                        title: "Courses" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "main"
                    }
                },
                {
                    path: "/courses/ms-cit",
                    name: "mscit",
                    component: loadCourse("mscit"),
                    meta: {
                        title: "MS-CIT" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "mscit",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-3d-modelling",
                    name: "klic3DModelling",
                    component: loadCourse("klic3DModelling"),
                    meta: {
                        title: "KLiC 3D Modelling" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-3d-texturing-and-lighting",
                    name: "klic3DTexturingAndLighting",
                    component: loadCourse("klic3DTexturingAndLighting"),
                    meta: {
                        title: "KLiC 3D Texturing & Lighting" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-advanced-excel",
                    name: "klicAdvancedExcel",
                    component: loadCourse("klicAdvancedExcel"),
                    meta: {
                        title: "KLiC Advanced Excel" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-advanced-python",
                    name: "klicAdvancedPython",
                    component: loadCourse("klicAdvancedPython"),
                    meta: {
                        title: "KLiC Advanced Python" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-advanced-tally-pro",
                    name: "klicAdvancedTallyPro",
                    component: loadCourse("klicAdvancedTallyPro"),
                    meta: {
                        title: "KLiC Advanced Tally Pro" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-autocad",
                    name: "klicAutoCAD",
                    component: loadCourse("klicAutoCAD"),
                    meta: {
                        title: "KLiC AutoCAD" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-ai-ml-basics",
                    name: "klicAIMLBasics",
                    component: loadCourse("klicAIMLBasics"),
                    meta: {
                        title: "KLiC AI-ML Basics" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-bfsi",
                    name: "klicBFSI",
                    component: loadCourse("klicBFSI"),
                    meta: {
                        title: "KLiC BFSI" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-content-illustration",
                    name: "klicContentIllustration",
                    component: loadCourse("klicContentIllustration"),
                    meta: {
                        title: "KLiC Content Illustration" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-c-cplusplus-programming",
                    name: "klicCCPlusPlusProgramming",
                    component: loadCourse("klicCCPlusPlusProgramming"),
                    meta: {
                        title: "KLiC C & C++ Programming" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-cplusplus-programming",
                    name: "klicCPlusPlusProgramming",
                    component: loadCourse("klicCPlusPlusProgramming"),
                    meta: {
                        title: "KLiC C++ Programming" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-c-programming",
                    name: "klicCProgramming",
                    component: loadCourse("klicCProgramming"),
                    meta: {
                        title: "KLiC C Programming" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-cyber-security",
                    name: "klicCyberSecurity",
                    component: loadCourse("klicCyberSecurity"),
                    meta: {
                        title: "KLiC Cyber Security" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-data-entry-and-data-management",
                    name: "klicDataEntryAndDataManagement",
                    component: loadCourse("klicDataEntryAndDataManagement"),
                    meta: {
                        title: "KLiC Data Entry and Data Management" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-desktop-support",
                    name: "klicDesktopSupport",
                    component: loadCourse("klicDesktopSupport"),
                    meta: {
                        title: "KLiC Desktop Support" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-digital-freelancing",
                    name: "klicDigitalFreelancing",
                    component: loadCourse("klicDigitalFreelancing"),
                    meta: {
                        title: "KLiC Digital Freelancing" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-dtp",
                    name: "klicDTP",
                    component: loadCourse("klicDTP"),
                    meta: {
                        title: "KLiC DTP (Adobe & CorelDRAW)" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-english",
                    name: "klicEnglish",
                    component: loadCourse("klicEnglish"),
                    meta: {
                        title: "KLiC English" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-google-workspace-expert",
                    name: "klicGoogleWorkspaceExpert",
                    component: loadCourse("klicGoogleWorkspaceExpert"),
                    meta: {
                        title: "KLiC Google Workspace Expert" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-graphic-designing",
                    name: "klicGraphicDesigning",
                    component: loadCourse("klicGraphicDesigning"),
                    meta: {
                        title: "KLiC Graphic Designing" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-hardware-support",
                    name: "klicHardwareSupport",
                    component: loadCourse("klicHardwareSupport"),
                    meta: {
                        title: "KLiC Hardware Support" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-iot",
                    name: "klicIOT",
                    component: loadCourse("klicIOT"),
                    meta: {
                        title: "KLiC IOT" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-it",
                    name: "klicIT",
                    component: loadCourse("klicIT"),
                    meta: {
                        title: "KLiC IT" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-it-for-art-teacher",
                    name: "klicITForArtTeacher",
                    component: loadCourse("klicITForArtTeacher"),
                    meta: {
                        title: "KLiC IT for Art Teacher" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-java",
                    name: "klicJava",
                    component: loadCourse("klicJava"),
                    meta: {
                        title: "KLiC Java" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-mobile-app-development",
                    name: "klicMobileAppDevelopment",
                    component: loadCourse("klicMobileAppDevelopment"),
                    meta: {
                        title: "KLiC Mobile App Development" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-network-support",
                    name: "klicNetworkSupport",
                    component: loadCourse("klicNetworkSupport"),
                    meta: {
                        title: "KLiC Network Support" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-office-assistance",
                    name: "klicOfficeAssistance",
                    component: loadCourse("klicOfficeAssistance"),
                    meta: {
                        title: "KLiC Office Assistance" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-photoshop",
                    name: "klicPhotoshop",
                    component: loadCourse("klicPhotoshop"),
                    meta: {
                        title: "KLiC Photoshop" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-python",
                    name: "klicPython",
                    component: loadCourse("klicPython"),
                    meta: {
                        title: "KLiC Python" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-retail-management",
                    name: "klicRetailManagement",
                    component: loadCourse("klicRetailManagement"),
                    meta: {
                        title: "KLiC Retail Management" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-scratch",
                    name: "klicScratch",
                    component: loadCourse("klicScratch"),
                    meta: {
                        title: "KLiC SCRATCH" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-security-support",
                    name: "klicSecuritySupport",
                    component: loadCourse("klicSecuritySupport"),
                    meta: {
                        title: "KLiC Security Support" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-smart-teacher",
                    name: "klicSmartTeacher",
                    component: loadCourse("klicSmartTeacher"),
                    meta: {
                        title: "KLiC Smart Teacher" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-soft-skills",
                    name: "klicSoftSkills",
                    component: loadCourse("klicSoftSkills"),
                    meta: {
                        title: "KLiC Soft Skills" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-tally-prime-with-gst",
                    name: "klicTallyPrimeWithGST",
                    component: loadCourse("klicTallyPrimeWithGST"),
                    meta: {
                        title: "KLiC Tally Prime with GST" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-video-editing",
                    name: "klicVideoEditing",
                    component: loadCourse("klicVideoEditing"),
                    meta: {
                        title: "KLiC Video Editing" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                {
                    path: "/courses/klic-web-designing",
                    name: "klicWebDesigning",
                    component: loadCourse("klicWebDesigning"),
                    meta: {
                        title: "KLiC Web Designing" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "KlicCourses",
                        isHeaderLight: true,
                        sitemap: "course"
                    }
                },
                // Tracks
                {
                    path: "/courses/accounting",
                    name: "accounting",
                    component: loadTracks("Accounting"),
                    meta: {
                        title: "Accounting" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/back-office",
                    name: "backOffice",
                    component: loadTracks("BackOffice"),
                    meta: {
                        title: "Back Office" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/designing",
                    name: "designing",
                    component: loadTracks("Designing"),
                    meta: {
                        title: "Designing" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/digital-arts",
                    name: "digitalArts",
                    component: loadTracks("DigitalArts"),
                    meta: {
                        title: "Digital Arts" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/digital-freelancing",
                    name: "digitalFreelancing",
                    component: loadTracks("DigitalFreelancing"),
                    meta: {
                        title: "Digital Freelancing" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/front-office",
                    name: "frontOffice",
                    component: loadTracks("FrontOffice"),
                    meta: {
                        title: "Front Office" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/it-hardware-and-networking",
                    name: "itHardwareAndNetworking",
                    component: loadTracks("ITHardwareAndNetworking"),
                    meta: {
                        title: "IT Hardware And Networking" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/management",
                    name: "management",
                    component: loadTracks("Management"),
                    meta: {
                        title: "Management" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/new-collar-jobs",
                    name: "newCollarJobs",
                    component: loadTracks("NewCollarJobs"),
                    meta: {
                        title: "New Collar Jobs" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                {
                    path: "/courses/programming",
                    name: "programming",
                    component: loadTracks("Programming"),
                    meta: {
                        title: "Programming" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "courses",
                        sitemap: "tracks"
                    }
                },
                // Solutions
                {
                    path: "/solutions",
                    name: "solutions",
                    component: loadView("Solutions"),
                    meta: {
                        title: "Solutions" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "solutions",
                        sitemap: "main"
                    }
                },
                {
                    path: "/solutions/knowledge-empowerment",
                    name: "knowledgeEmpowerment",
                    component: loadSolution("KnowledgeEmpowerment"),
                    meta: {
                        title: "Knowledge Empowerment" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "solutions",
                        sitemap: "solutions"
                    }
                },
                {
                    path: "/solutions/egovernance",
                    name: "eGovernance",
                    component: loadSolution("eGovernance"),
                    meta: {
                        title: "eGovernance" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "egov",
                        sitemap: "solutions"
                    }
                },
                {
                    path: "/solutions/campuslive",
                    name: "campusLive",
                    component: loadSolution("CampusLive"),
                    meta: {
                        title: "CampusLive" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "campusLive",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/deep",
                    name: "deep",
                    component: loadSolution("Deep"),
                    meta: {
                        title: "Deep" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "deep",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/digital-university",
                    name: "digitalUniversity",
                    component: loadSolution("DigitalUniversity"),
                    meta: {
                        title: "Digital University" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "digitalUniversity",
                        sitemap: "knowledgeEmpower"
                    }
                },
                // {
                //     path: "/solutions/dnext",
                //     name: "DNExT",
                //     component: loadSolution("Dnext"),
                //     meta: {
                //         title: "DNExT" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant"
                //     }
                // },
                // {
                //     path: "/solutions/app-suit",
                //     name: "AppSuit",
                //     component: loadSolution("AppSuit"),
                //     meta: {
                //         title: "AppSuit" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //         ftFaqsContent: "appSuit"
                //     }
                // },
                {
                    path: "/solutions/auctionlive",
                    name: "auctionLive",
                    component: loadSolution("AuctionLive"),
                    meta: {
                        title: "auctionLive" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "auctionLive",
                        sitemap: "eGov"
                    }
                },                
                {
                    path: "/solutions/easy",
                    name: "easy",
                    component: loadSolution("Easy"),
                    meta: {
                        title: "Easy" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "easy",
                        sitemap: "knowledgeEmpower"
                    }
                },
                // {
                //     path: "/solutions/edumart",
                //     name: "eduMart",
                //     component: loadSolution("EduMart"),
                //     meta: {
                //         title: "EduMart" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //         ftFaqsContent: "EduMart"
                //     }
                // },
                {
                    path: "/solutions/eralive",
                    name: "eraLive",
                    component: loadSolution("eraLive"),
                    meta: {
                        title: "EraLive" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "eraLive",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/examlive",
                    name: "examLive",
                    component: loadSolution("ExamLive"),
                    meta: {
                        title: "ExamLive" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "examLive",
                        sitemap: "knowledgeEmpower"
                    }
                },
                // {
                //     path: "/solutions/hrms",
                //     name: "HRMS",
                //     component: loadSolution("Hrms"),
                //     meta: {
                //         title: "HRMS" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //         ftFaqsContent: "hrms"
                //     }
                // },
                {
                    path: "/solutions/ilike",
                    name: "iLike",
                    component: loadSolution("iLike"),
                    meta: {
                        title: "iLike" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "iLike",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/janlabh",
                    name: "janLabh",
                    component: loadSolution("JanLabh"),
                    meta: {
                        title: "JanLabh" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "janLabh",
                        sitemap: "eGov"
                    }
                },
                // {
                //     path: "/solutions/klic-courses",
                //     name: "KLiCCourses",
                //     component: loadSolution("KlicCourses"),
                //     meta: {
                //         title: "KLiC Courses" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant"
                //     }
                // },
                {
                    path: "/solutions/learnlive",
                    name: "learnLive",
                    component: loadSolution("LearnLive"),
                    meta: {
                        title: "LearnLive" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "learnLive",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/legislive",
                    name: "legisLive",
                    component: loadSolution("LegisLive"),
                    meta: {
                        title: "LegisLive" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "legisLive",
                        sitemap: "eGov"
                    }
                },
                {
                    path: "/solutions/mfs",
                    name: "mfs",
                    component: loadSolution("Mfs"),
                    meta: {
                        title: "MFS" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "mfs",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/mastering",
                    name: "mastering",
                    component: loadSolution("Mastering"),
                    meta: {
                        title: "Mastering" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "mastering",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/mom",
                    name: "mom",
                    component: loadSolution("MOM"),
                    meta: {
                        title: "MOM" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        sitemap: "knowledgeEmpower"
                    }
                },
                // {
                //     path: "/solutions/ms-cit",
                //     name: "MS-CIT",
                //     component: loadSolution("Mscit"),
                //     meta: {
                //         title: "MS-CIT" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant"
                //     }
                // },
                // {
                //     path: "/solutions/proctorlive",
                //     name: "ProctorLive",
                //     component: loadSolution("ProctorLive"),
                //     meta: {
                //         title: "ProctorLive" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant"
                //     }
                // },
                {
                    path: "/solutions/recruitlive",
                    name: "recruitLive",
                    component: loadSolution("RecruitLive"),
                    meta: {
                        title: "RecruitLive" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "recruitLive",
                        sitemap: "eGov"
                    }
                },
                // {
                //     path: "/solutions/reportlive",
                //     name: "ReportLive",
                //     component: loadSolution("ReportLive"),
                //     meta: {
                //         title: "Report Live" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //     }
                // },
                // {
                //     path: "/solutions/sets",
                //     name: "SeTS",
                //     component: loadSolution("Sets"),
                //     meta: {
                //         title: "SeTS" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //         ftFaqsContent: "sets"
                //     }
                // },
                // {
                //     path: "/solutions/school-mis",
                //     name: "SchoolMIS",
                //     component: loadSolution("SchoolMIS"),
                //     meta: {
                //         title: "School MIS" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //         ftFaqsContent: "schoolMIS"
                //     }
                // },
                {
                    path: "/solutions/sdmms",
                    name: "sdmms",
                    component: loadSolution("SDMMS"),
                    meta: {
                        title: "SDMMS" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "sdmms",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/skilllive",
                    name: "skillLive",
                    component: loadSolution("SkillLive"),
                    meta: {
                        title: "Skill Live" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "skillLive",
                        sitemap: "knowledgeEmpower"
                    }
                },
                // {
                //     path: "/solutions/sugarmart",
                //     name: "SugarMart",
                //     component: loadSolution("SugarMart"),
                //     meta: {
                //         title: "SugarMart" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //         ftFaqsContent: "sets"
                //     }
                // },               
                {
                    path: "/solutions/tenderlive",
                    name: "tenderLive",
                    component: loadSolution("TenderLive"),
                    meta: {
                        title: "TenderLive" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "tenderLive",
                        sitemap: "eGov"
                    }
                },
                {
                    path: "/solutions/tilimili",
                    name: "tilimili",
                    component: loadSolution("Tilimili"),
                    meta: {
                        title: "Tilimili" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "tilimili",
                        sitemap: "knowledgeEmpower"
                    }
                },
                {
                    path: "/solutions/vanmitra",
                    name: "vanmitra",
                    component: loadSolution("Vanmitra"),
                    meta: {
                        title: "Vanmitra" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "vanmitra",
                        sitemap: "eGov"
                    }
                },
                // Gallery
                {
                    path: "/gallery",
                    name: "gallery",
                    component: loadView("Gallery"),
                    meta: {
                        title: "Gallery" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "main"
                    }
                },
                {
                    path: "/gallery/foundation-day",
                    name: "fDay",
                    component: loadGallery("foundationDay"),
                    meta: {
                        title: "Foundation Day" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "gallery"
                    }
                },
                {
                    path: "/gallery/community",
                    name: "community",
                    component: loadGallery("bringCommunityTogether"),
                    meta: {
                        title: "Bring Community Together" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "gallery"
                    }
                },
                {
                    path: "/gallery/pune-office",
                    name: "ofcPune",
                    component: loadGallery("officePune"),
                    meta: {
                        title: "Pune Office" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common"
                    }
                },
                {
                    path: "/gallery/seawoods-office",
                    name: "ofcSwd",
                    component: loadGallery("officeSeawoods"),
                    meta: {
                        title: "Seawoods Office" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common"
                    }
                },
                {
                    path: "/gallery/video-gallery",
                    name: "videoRecording",
                    component: loadGallery("Videos"),
                    meta: {
                        title: "Videos" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "gallery"
                    }
                },
                //events
                // {
                //     path: "/events",
                //     name: "events",
                //     component: loadView("Events"),
                //     meta: {
                //         title: "Events" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant"
                //     }
                // },
                // about
                {
                    path: "/about",
                    name: "about",
                    component: loadView("About"),
                    meta: {
                        title: "About" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "main"
                    }
                },
                // contact
                {
                    path: "/contact",
                    name: "contact",
                    component: loadView("Contact"),
                    meta: {
                        title: "Contact" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "contact",
                        sitemap: "main"
                    }
                },
                // Career
                {
                    path: "/career",
                    name: "career",
                    component: loadView("Career"),
                    meta: {
                        title: "Career" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "career",
                        sitemap: "main"
                    },
                },
                {
                    path: "/careers/assistant-manager-it-infrastructure",
                    name: "AssistantManagerITInfrastructure",
                    component: loadCareer("AssistantManagerITInfrastructure"),
                    meta: {
                        title: "Assistant Manager IT Infrastructure" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "career"
                    }
                },
                // {
                //     path: "/career/learning-experience-architech",
                //     name: "LearningExperienceArchitech",
                //     component: loadCareer("LearningExperienceArchitech"),
                //     meta: {
                //         title: "Learning Experience Architech" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //         ftFaqsContent: "career"
                //     }
                // },
                // {
                //     path: "/career/management-trainee-scheme-2024-2025",
                //     name: "ManagementTraineeScheme20242025",
                //     component: loadCareer("ManagementTraineeScheme20242025"),
                //     meta: {
                //         title: "Management Trainee Scheme 2024 - 2025" + siteName,
                //         lang: "en",
                //         icon: "mdi mdi-information-variant",
                //         ftFaqsContent: "career"
                //     }
                // },
                {
                    path: "/partners",
                    name: "partners",
                    component: loadView("Partners"),
                    meta: {
                        title: "Network Partners" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "partners",                        
                        sitemap: "main"
                    }
                },
                {
                    path: "/faqs",
                    name: "faqs",
                    component: loadView("Faqs"),
                    meta: {
                        title: "FAQs" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftHideFAQs: true,
                        sitemap: "main"
                    },
                },
                {
                    path: "/equity-profile",
                    name: "equityProfile",
                    component: loadView("EquityProfile"),
                    meta: {
                        title: "Equity Profile" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "useful"
                    },
                },
                {
                    path: "/sitemap",
                    name: "Sitemap" + siteName,
                    component: loadView("Sitemap"),
                    meta: {
                        title: "Sitemap" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftSimple: true,
                        ftHideFAQs: true
                    },
                },
                {
                    path: "/shareholders",
                    name: "shareholders",
                    component: loadView("Shareholders"),
                    meta: {
                        title: "Shareholders" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "shareholders",
                        sitemap: "main"
                    },
                },
                {
                    path: "/disclaimer",
                    name: "disclaimer",
                    component: loadView("Disclaimer"),
                    meta: {
                        title: "Disclaimer" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "useful"
                    },
                },
                {
                    path: "/rti",
                    name: "rti",
                    component: loadView("RTI"),
                    meta: {
                        title: "RTI" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "rti",
                        sitemap: "useful"
                    },
                },
                {
                    path: "/tenders",
                    name: "tenders",
                    component: loadView("Tenders"),
                    meta: {
                        title: "Tenders" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "tender",
                        sitemap: "main"
                    },
                },
                {
                    path: "/privacy-policy",
                    name: "privacyPolicy",
                    component: loadView("PrivacyPolicy"),
                    meta: {
                        title: "Privacy Policy" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "useful"
                    },
                },
                {
                    path: "/quality-policy",
                    name: "qualityPolicy",
                    component: loadView("QualityPolicy"),
                    meta: {
                        title: "Quality Policy" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "quality",
                        sitemap: "useful"
                    },
                },
                {
                    path: "/downloads",
                    name: "downloads",
                    component: loadView("Downloads"),
                    meta: {
                        title: "Downloads" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: "useful"
                    },
                },
                {
                    path: "/archives/news/toi-article-mkcl-not-blacklisted",
                    name: "toi",
                    component: loadView("TOIArticleMKCLNotBlacklisted"),
                    meta: {
                        title: "TOI Article MKCL Not Blacklisted" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        ftFaqsContent: "common",
                        sitemap: ""
                    },
                },
                {
                    path: "/:pathMatch(.*)*",
                    // alias: '*',
                    name: "notFound",
                    component: loadView("notFound"),
                    meta: {
                        title: "404" + siteName,
                        lang: "en",
                        icon: "mdi mdi-information-variant",
                        isHeaderLight: true,
                        ftSimple: true
                    },
                }
            ]
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
});
